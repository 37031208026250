import React, {useState, useEffect, useRef} from 'react';
import './Careers.css';
import lottie from 'lottie-web';
import animationData from '../animations/careerMain.json';
import CareersIllustration from './images/careersIllustration.svg';
import OpeningEntry from '../components/OpeningEntry';
import EmptyTable from './images/emptyTable.svg';
import OnHoverScrollContainer from "./CustomScrollDiv";
import {
  Link,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  Loader,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function Openings(props) {

  const [jobList, setJobList] = useState([]);
  const list = [];
  const [apiFetched, setApiFetched] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const containerRef = useRef(null);


  const myRef = useRef(null);
  const myLottie = useRef(null);

  const bangaloreCount= useRef(0);
  const sanJoseCount = useRef(0);
  const berlinCount = useRef(0);

  const handleKeyDown = (event, value) => {
    const navItems = document.querySelectorAll(".focusable-filter");
    const currentFocusIndex = Array.from(navItems).indexOf(document.activeElement);

    if (event.key === "ArrowRight") {
    event.preventDefault();
    if (currentFocusIndex < navItems.length - 1) {
      let nextIndex = currentFocusIndex + 1;
      navItems[nextIndex].focus();
    }
  } else if (event.key === "ArrowLeft") {
    event.preventDefault();
    if (currentFocusIndex > 0) {
      let prevIndex = currentFocusIndex - 1;
      navItems[prevIndex].focus();
    }
  } else if (event.key === "Tab" && !event.shiftKey) {
      event.preventDefault();
      const jobLink = document.querySelector('.jobLinks');
      jobLink.focus();
    } else if(event.key === "Enter") {
      filterJobList(event, value);
      if(value === 'clear') {
        navItems[0].focus();
      }
    }
  };



  useEffect(() => {

  createJobList();

  myLottie.current = lottie.loadAnimation({
   container: myRef.current,
   renderer: "svg",
   loop: false,
   autoplay: false,
   animationData: animationData
 });

}, []);




const playReverse = () => {
  myLottie.current.setDirection(-1);
  myLottie.current.play();
};
const playForward = () => {
  myLottie.current.setDirection(1);
  myLottie.current.play();
};


  const createJobList = (xmlDOM) => {

    fetch('https://us-central1-design-studies.cloudfunctions.net/eightfold-function')
       .then((res) => {
         return res.json();
       })
       .then((data) => {
           data.jobs.job.forEach(function(element) {
             let job = {};

             job.role = element['position'][0]['title'][0];
             job.location = element['position'][0]['location'][0];
             if(job.location === "United States, United States") {
               job.location = "San Jose, United States";
             }
             job.url =  element['how-to-apply'][0]['application-url'][0];
             job.key = element['position'][0]['city'][0];
             if (job.key === 'United States') {
               job.key = 'San Jose';
             }
             list.push(job);
           });
           setApiFetched(true);
           setJobList((prevState) => {
             return list;
           });
           const newOpeningsComponent = () => {
             return (
               <Title className="sectionDescription" style={{color: '#131313'}}>Viewing<span style={{ padding: '5px', color: '#131313'}}>{list.length}</span>{list.length > 1 ? 'Openings' : 'Opening'}</Title>
             );
           }
           setOpenings(newOpeningsComponent());
           bangaloreCount.current = list.filter(function(job) {
           return job.key === 'Bangalore';
         }).length;
         sanJoseCount.current = list.filter(function(job) {
         return job.key === 'San Jose';
       }).length;

       berlinCount.current = list.filter(function(job) {
       return job.key === 'Berlin';
       }).length;



           setFilters(filterComponent());
           addRemoveScroll();


       });


  }

  const addRemoveScroll = () => {
    const jobContainer = document.getElementById('jobList');
    if(jobContainer && jobContainer.scrollHeight > 445) {
       setIsOverflow(true);
    } else {
       setIsOverflow(false);
     }
  }
  const filterComponent = () => {
    return (
        <FlexLayout justifyContent="flex-end" className="filters" itemSpacing="5px">
        <div
           className="openingFilter focusable-filter"
           tabIndex="0"
           role="button"
           onClick={(e) => filterJobList(e, 'Bangalore')}
           onKeyDown={(e) => {handleKeyDown(e, 'Bangalore')}}>
         Bangalore - {bangaloreCount.current}</div>
   <div className="openingFilter focusable-filter"  onClick={(e) => filterJobList(e, 'San Jose')}
   tabIndex="0"
   role="button"
   onKeyDown={(e) => {handleKeyDown(e, 'San Jose')}}>San Jose - {sanJoseCount.current}</div>
   <div className="openingFilter focusable-filter"  onClick={(e) => filterJobList(e, 'Berlin')}
   tabIndex="0"
   role="button"
   onKeyDown={(e) => {handleKeyDown(e, 'Berlin')}}>Berlin - {berlinCount.current}</div>
   </FlexLayout>
  );
  }

  const viewOpeningsComponent = () => {
    return (
      <Title className="sectionDescription" style={{color: '#131313'}}>Viewing<span style={{ padding: '5px', color: '#131313'}}>{list.length}</span>{list.length > 1 ? 'Openings' : 'Opening'}</Title>
    );
  }

  const removeActiveClass = () => {
    Array.from(document.getElementsByClassName('filters')[0].children).forEach(function(element) {
        if(element.classList.contains('activeClass')) {
          element.classList.remove('activeClass');
        }
    });
  }

  const [filters, setFilters] = useState(filterComponent());
  const [openings, setOpenings] = useState(viewOpeningsComponent());



function filterJobList(event, location) {
  if(location !== 'clear') {
  removeActiveClass();
  event.target.classList.add('activeClass');
  const result = list.filter(function(job) {
    return job.key === location;
  });
  setJobList(result);
  const newFilterComponent = () => {
    return (
        <FlexLayout justifyContent="flex-end" alignItems="center" className="filters" itemSpacing="5px">
      <div className="openingFilter focusable-filter" tabIndex="0" onClick={(e) => filterJobList(e, 'Bangalore')}
      role="button"
      onKeyDown={(e) => {handleKeyDown(e, 'Bangalore')}}>Bangalore - {bangaloreCount.current}</div>
   <div className="openingFilter focusable-filter" tabIndex="0" onClick={(e) => filterJobList(e, 'San Jose')}
   role="button"
   onKeyDown={(e) => {handleKeyDown(e, 'San Jose')}}>San Jose - {sanJoseCount.current}</div>
   <div className="openingFilter focusable-filter" tabIndex="0" onClick={(e) => filterJobList(e, 'Berlin')}
   role="button"
   onKeyDown={(e) => {handleKeyDown(e, 'Berlin')}}>Berlin - {berlinCount.current}</div>
   <div className="sectionDescriptionExtraSmall focusable-filter" tabIndex="0" onClick={(e) => filterJobList(e, 'clear')} style={{marginLeft: '15px', padding: '2px', cursor: 'pointer'}}
   role="button"
   onKeyDown={(e) => {handleKeyDown(e, 'clear')}}>Clear All</div>
   </FlexLayout>
  );
  }
  setFilters(newFilterComponent());
  const newOpeningsComponent = () => {
    return (
      <Title className="sectionDescription" id="filterDescription" style={{color: '#131313'}}>Viewing<span style={{ paddingLeft: '5px', color: '#131313'}}>{result.length}</span> out of <span style={{ paddingRight: '5px', color: '#131313'}}>{list.length}</span>{list.length > 1 ? 'Openings' : 'Opening'}</Title>
    );
  }
    setOpenings(newOpeningsComponent());
} else {
  removeActiveClass();
  setJobList(list);
  setFilters(filterComponent());
  setOpenings(viewOpeningsComponent());
}

setTimeout(() => {
  addRemoveScroll();
}, 0);

}





return (
  <FlexLayout>
   <FlexLayout flexDirection="column" alignItems="flex-end" justifyContent="center" style={{ background: '#F9F9F9', padding: '110px 0px 110px 303px', position: 'relative', marginTop: '-150px', marginLeft: '-300px', flex: '1 1 auto'}}>
   <FlexLayout flexDirection="column" className="abc">

    <div ref={myRef} className="careersIllustration" onMouseEnter={()=>{playForward()}}
     onMouseLeave={()=>{playReverse()}}></div>
     <Title size="h1" className="visuallyHidden">Introduction of Nutanix Design Team</Title>
    <Title size="h2" className="sectionTitle" style={{width: '80%'}}>Our greatest asset is you!</Title>

        <Paragraph className="sectionDescription" style={{width: '75%'}}>Learn about our open roles and become part of the crew. Help build seamless product experiences that our customers love and grow professionally and personally.</Paragraph>

      </FlexLayout>
      </FlexLayout>
      <FlexLayout flexDirection="column" alignItems="flex-start" style={{paddingLeft: '37px', marginTop: '-30px'}}>
      <FlexLayout flexDirection="column" className="abc">
       <FlexLayout>
       <Title size="h3" className="sectionDescriptionBoldLarger" style={{ fontSize: '20px'}}>Job Listings</Title>
       {openings}
       </FlexLayout>
       <FlexLayout justifyContent="space-between" alignItems="center">

       {filters}
       </FlexLayout>
      {isOverflow ? <OnHoverScrollContainer>
      <FlexLayout flexDirection="column" style={{height: '430px', paddingTop: '15px'}} id="jobList" ref={containerRef}>

        {jobList.length !== 0 ? jobList.map((job, index) => (
          <OpeningEntry role={job.role} location={job.location} key={index} unique={index} link={job.url} isMobile={false}/>
        )) : apiFetched ?
          <FlexLayout flexDirection="column" alignItems="center" itemSpacing="30px"><img src={EmptyTable} style={{height: '300px', width: '300px'}}/><Paragraph className="sectionDescription"> There are no openings at the moment.</Paragraph></FlexLayout> : <Loader tip="Loading..." overlay={ true } data-test-id="overlay" />}

      </FlexLayout>
      </OnHoverScrollContainer> :
      <FlexLayout flexDirection="column" style={{height: '430px', paddingTop: '15px'}} id="jobList" ref={containerRef} className={isOverflow ? 'jobOverflow' : ''}>

        {jobList.length !== 0 ? jobList.map((job, index) => (
          <OpeningEntry role={job.role} location={job.location} key={index} unique={index} link={job.url} isMobile={false}/>
        )) : apiFetched ?
          <FlexLayout flexDirection="column" alignItems="center" itemSpacing="30px"><img src={EmptyTable} style={{height: '300px', width: '300px'}}/><Paragraph className="sectionDescription"> There are no openings at the moment.</Paragraph></FlexLayout> : <Loader tip="Loading..." overlay={ true } data-test-id="overlay" />}

      </FlexLayout> }



        <Paragraph className="sectionDescriptionSmall" style={{background: '#F3F4FF', marginTop: '50px', padding: '10px', borderRadius: '4px', color: '#5D5D5D'}}>Didn’t find an open position but are keen on exploring a future opportunity with us? <Link className="linkSmall" id="formLink" href="https://forms.gle/ywRtz58eSt7ZGGAk9" target="_blank" style={{borderBottom: '1px solid #7855FA'}}>Drop a note here</Link></Paragraph>
      </FlexLayout>
      </FlexLayout>
      </FlexLayout>


);


}

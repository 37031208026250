import React, {useState, useEffect, useRef} from 'react';
import './Careers.css';
import CareersIllustration from './images/careersIllustration.svg';
import OpeningEntry from '../components/OpeningEntry';
import EmptyTable from './images/emptyTable.svg';
import {
  Link,
  TextLabel,
  FlexItem,
  StackingLayout,
  FlexLayout,
  Loader,
  Paragraph,
  Button,
  Title
} from '@nutanix-ui/prism-reactjs';

export default function MobileOpenings(props) {


  const [jobList, setJobList] = useState([]);
  const list = [];
  const [apiFetched, setApiFetched] = useState(false);
  const bangaloreCount= useRef(0);
  const sanJoseCount = useRef(0);
  const berlinCount = useRef(0);
  useEffect(() => {
   createJobList(props.jobList);

}, []);

const createJobList = (xmlDOM) => {


  fetch('https://us-central1-design-studies.cloudfunctions.net/eightfold-function')
     .then((res) => {
       return res.json();
     })
     .then((data) => {
         data.jobs.job.forEach(function(element) {
           let job = {};

           job.role = element['position'][0]['title'][0];
           job.location = element['position'][0]['location'][0];
           if(job.location === "United States, United States") {
             job.location = "San Jose, United States";
           }
           job.url =  element['how-to-apply'][0]['application-url'][0];
           job.key = element['position'][0]['city'][0];
           if (job.key === 'United States') {
             job.key = 'San Jose';
           }
           list.push(job);
         });
         setApiFetched(true);
         setJobList((prevState) => {
           return list;
         });
         const newOpeningsComponent = () => {
           return (
             <Title className="sectionDescription" style={{color: '#131313'}}>Viewing<span style={{ padding: '5px', color: '#131313'}}>{list.length}</span>{list.length > 1 ? 'Openings' : 'Opening'}</Title>
           );
         }
         setOpenings(newOpeningsComponent());
         bangaloreCount.current = list.filter(function(job) {
         return job.key === 'Bangalore';
       }).length;
       sanJoseCount.current = list.filter(function(job) {
       return job.key === 'San Jose';
     }).length;

     berlinCount.current = list.filter(function(job) {
     return job.key === 'Berlin';
     }).length;



         setFilters(filterComponent());

     });
}

const filterComponent = () => {
  return (
      <FlexLayout justifyContent="flex-end" className="filters" itemSpacing="5px">
    <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'Bangalore')}>Bangalore - {bangaloreCount.current}</FlexItem>
 <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'San Jose')}>San Jose - {sanJoseCount.current}</FlexItem>
 <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'Berlin')}>Berlin - {berlinCount.current}</FlexItem>
 </FlexLayout>
);
}

  const viewOpeningsComponent = () => {
    return (
      <Title className="sectionDescriptionBoldLarge">Viewing<span style={{ padding: '5px'}}>{list.length}</span>Openings</Title>
    );
  }

  const removeActiveClass = () => {
    Array.from(document.getElementsByClassName('filters')[0].children).forEach(function(element) {
        if(element.classList.contains('activeClass')) {
          element.classList.remove('activeClass');
        }
    });
  }



const [filters, setFilters] = useState(filterComponent());
const [openings, setOpenings] = useState(viewOpeningsComponent());

function filterJobList(event, location) {
  if(location !== 'clear') {
  removeActiveClass();
  event.target.classList.add('activeClass');
  const result = list.filter(function(job) {
    return job.key === location;
  });
  setJobList(result);
  const newFilterComponent = () => {
    return (
        <FlexLayout justifyContent="flex-end" alignItems="center" className="filters" itemSpacing="5px">
      <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'Bangalore')}>Bangalore - {bangaloreCount.current}</FlexItem>
   <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'San Jose')}>San Jose - {sanJoseCount.current}</FlexItem>
   <FlexItem className="openingFilter" onClick={(e) => filterJobList(e, 'Berlin')}>Berlin - {berlinCount.current}</FlexItem>
   <FlexItem className="sectionDescriptionExtraSmall" onClick={(e) => filterJobList(e, 'clear')} style={{marginLeft: '15px', cursor: 'pointer'}}>Clear All</FlexItem>
   </FlexLayout>
  );
  }
  setFilters(newFilterComponent());
  const newOpeningsComponent = () => {
    return (
      <Title className="sectionDescription" style={{color: '#131313'}}>Viewing<span style={{ paddingLeft: '5px', color: '#131313'}}>{result.length}</span> out of <span style={{ paddingRight: '5px', color: '#131313'}}>{list.length}</span>Openings</Title>
    );
  }
    setOpenings(newOpeningsComponent());
} else {
  removeActiveClass();
  setJobList(list);
  setFilters(filterComponent());
  setOpenings(viewOpeningsComponent());
}
}



return (


  <FlexLayout flexDirection="column">
   <img src={CareersIllustration} className="fullBleedImage"/>
    <Title className="sectionTitle" style={{width: '80%', paddingTop: '80px'}}>Our greatest asset is you!</Title>
    <Paragraph className="sectionDescription">Learn about our open roles and become part of the crew. Help build seamless product experiences that our customers love and grow professionally and personally.</Paragraph>
    <FlexLayout justifyContent="space-between" alignItems="center">

    {filters}
    </FlexLayout>
    <FlexLayout flexDirection="column" style={{ paddingTop: '10px'}}>

      {jobList.length !== 0 ? jobList.map((job, index) => (
        <OpeningEntry role={job.role} location={job.location} key={index} link={job.url} isMobile={true}/>
      )) : apiFetched ? <FlexLayout flexDirection="column" alignItems="center" justifyContent="space-between"><img src={EmptyTable} style={{height: '300px', width: '300px'}}/><Paragraph className="sectionDescription"> There are no openings at the moment.</Paragraph></FlexLayout> : <Loader tip="Loading..." overlay={ true } data-test-id="overlay" />}

    </FlexLayout>
    <Paragraph className="sectionDescriptionSmall" style={{color: '#5D5D5D', background: '#F3F4FF', padding: '10px'}}>Didn’t find an open position but are keen on exploring a future opportunity with us?<Link className="linkSmall" href="https://forms.gle/ywRtz58eSt7ZGGAk9" target="_blank" style={{borderBottom: '1px solid #7855FA'}}>Drop a note here</Link></Paragraph>

  </FlexLayout>

);


}
